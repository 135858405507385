import React from "react";
import Footer from "../footer/Footer";
import { Link } from "gatsby";
import logo from "../../images/logo.svg";
import "./layout.styles.scss";
import CookieBanner from "../cookieBanner/CookieBanner";

const Layout = ({ children }) => {
  return (
    <>
      <header className="company-logo__container">
        <Link className="company-logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
      </header>
      {children}
      <Footer footerInformtation="© gustavgusto" />
      <CookieBanner />
    </>
  );
};

export default Layout;
