import React from "react";
import CookieConsent from "react-cookie-consent";
import "./cookieBanner.styles.scss";

const CookieBanner = () => {

  const boxShadow = "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12)"

  return (
    <CookieConsent
      location="bottom"
      buttonText="Akzeptieren"
      cookieName="gdpr-popup"
      de
      style={{ background: "#333333", fontSize: "13px", opacity: "90%" }}
      buttonStyle={{ color: "white", background: "#f2a222", fontSize: "13px", borderRadius: "8px" }}
      expires={365}
    >
      Diese Website verwendet Cookies. Cookies helfen uns bei der Bereitstellung unserer Inhalte und Dienste. Durch die weitere Nutzung der Website stimmst Du der Verwendung zu.
    </CookieConsent>
  );
};

export default CookieBanner;
