import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ title, description, robots, titleSuffix = true }) => {

    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
    `)
    title = title + (titleSuffix ? ` | ${data.site.siteMetadata.title}` : "".trim())
    description = description || data.site.siteMetadata.description
    robots = robots || "index"
    
    return (
        <Helmet
            htmlAttributes={{ lang: "de" }}
            title={title}
            meta={[
                { name: "description", content: description },
                { name: "viewport", content: "width=device-width, initial-scale=1, maximum-scale=5" },
                { name: "robots", content: robots }

            ]}
        >
        </Helmet>
    )
}

export default Head
