import { FiInstagram, FiFacebook, FiTwitter, FiMail, FiLinkedin, FiYoutube } from "react-icons/fi";


export const ContactForm = (handleIsOpen) => {
    return {
        icon: FiMail,
        platform: "form",
        url: "/",
        onClick: (event) => { event.preventDefault(); handleIsOpen(); }
    }
}

const emailAddress = "gagan@gustavgusto.de"

export const getEmail = (handleIsOpen = null) => [
    {
        icon: FiMail,
        platform: "email",
        email: emailAddress,
        url: "/",
        // onClick: (event) => { event.preventDefault(); handleIsOpen();}
    },
]


export const socialMediaIcons = {
    instagram: FiInstagram,
    linkedin: FiLinkedin,
    youtube: FiYoutube,
    facebook: FiFacebook,
    twitter: FiTwitter
}