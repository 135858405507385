import { Link } from "gatsby";
import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ContactForm, socialMediaIcons, getEmail } from "../../constants/contactLinks";
import { ContactModalDispatchContext } from "../../provider/ContactModalProvider";
import { IconMatcher } from "../../utils/IconMatcher";
import IconPlusText from "../iconPlusText/IconPlusText";
import "./footer.styles.scss";

const Footer = ({ footerInformtation }) => {

    const { handleIsOpen } = useContext(ContactModalDispatchContext);

    const data = useStaticQuery(graphql`
    query {
        allContentfulAllFooterLinks {
            nodes {
                title
                    footerLinks {
                        slug
                        title
                    }
            }
        }
        allContentfulSocialMediaLinks {
            nodes {
                url
                platform
            }
        }
    }
    `
    )

    const allFooterLinks = data.allContentfulAllFooterLinks.nodes;
    let allSocialMediaLinks = IconMatcher(data.allContentfulSocialMediaLinks.nodes, socialMediaIcons);

    allSocialMediaLinks.push(ContactForm(handleIsOpen));

    const email = getEmail()[0]

    return (
        <footer>
            <div className="footer__body">
                <div className="footer__social-media">
                    <div>
                        {allSocialMediaLinks.map((item, index) => {
                            let Icon = item.icon;
                            return <a aria-label={item.platform} target="_blank" rel="noopener noreferrer" className="footer__social-media__item" key={index} href={item.url} onClick={item.onClick}><Icon /></a>
                        })}
                    </div>
                    <a href={`mailto:${email.email}`}>{email.email}</a>
                </div>
                <div className="footer__links">
                    {allFooterLinks.map((footerLinks, firstIndex) =>
                        <div key={firstIndex} className="footer__link__section">
                            <strong className="footer__link__section__title">{footerLinks.title}</strong>
                            {footerLinks.footerLinks.map((footerLink, secondIndex) =>
                                <Link
                                    className="footer__link"
                                    key={secondIndex}
                                    to={`/${footerLink.slug}`}
                                >{footerLink.title}</Link>)}
                        </div>)}
                </div>
                <div className="footer__information">
                    <p>{footerInformtation} {new Date().getFullYear()} | developed with ❤️ by <a href="https://www.web-dna.de/">web DnA</a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
